/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const mGlobal = () => {
	import('../components/header/header').then(({ default: mHeader }) => { mHeader(); });

	if (document.querySelector('.m-hp-hero')) import('../components/hp-hero/hp-hero').then(({ default: mHpHero }) => { mHpHero(); });
	if (document.querySelector('.m-hp-events')) import('../components/hp-events/hp-events').then(({ default: mHpEvents }) => { mHpEvents(); });
	if (document.querySelector('.m-hp-essence')) import('../components/hp-essence/hp-essence').then(({ default: mHpEssence }) => { mHpEssence(); });
	if (document.querySelector('.m-page-nav')) import('../components/page-nav/page-nav').then(({ default: mPageNav }) => { mPageNav(); });
	if (document.querySelector('.m-donate-form')) import('../components/donate-form/donate-form').then(({ default: mDonateForm }) => { mDonateForm(); });
	if (document.querySelector('.m-volunteer-form')) import('../components/volunteer-form/volunteer-form').then(({ default: mVolunteerForm }) => { mVolunteerForm(); });
	if (document.querySelector('.m-fire-two-col')) import('../components/fire-two-col/fire-two-col').then(({ default: mFireTwoCol }) => { mFireTwoCol(); });
	if (document.querySelector('.m-hero')) import('../components/hero/hero').then(({ default: mHero }) => { mHero(); });
	if (document.querySelector('.m-team-grid')) import('../components/team-grid/team-grid').then(({ default: mTeamGrid }) => { mTeamGrid(); });
	if (document.querySelector('.m-content-slider')) import('../components/content-slider/content-slider').then(({ default: mContentSlider }) => { mContentSlider(); });
	if (document.querySelector('.m-impact')) import('../components/impact/impact').then(({ default: mImpact }) => { mImpact(); });
	if (document.querySelector('.m-marquee-hero')) import('../components/marquee-hero/marquee-hero').then(({ default: mMarqueeHero }) => { mMarqueeHero(); });
	if (document.querySelector('.m-event-gallery')) import('../components/event-gallery/event-gallery').then(({ default: mEventGallery }) => { mEventGallery(); });
	if (document.querySelector('.m-conference-entry')) import('../components/conference-entry/conference-entry').then(({ default: mConferenceEntry }) => { mConferenceEntry(); });

	// Lite embeds
	if (document.querySelectorAll('.m-lite-embed')) {
		import('@justinribeiro/lite-youtube');
	}

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('m-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow   = document.createElement('span');
		wrapper.classList.add('m-form__select');
		arrow.classList.add('m-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('m-form__select-field');
	});

	/**
	 * Lazy videos.
	 */
	const videoObserver = new IntersectionObserver((entries, o) => {
		entries.forEach((video) => {
			if (!video.isIntersecting) {
				return;
			}

			const sources = video.target.querySelectorAll('source');

			[...sources].forEach((source) => {
				source.setAttribute('src', source.getAttribute('data-src'));
			});

			video.target.load();
			video.target.classList.remove('m-lazy-video');
			o.unobserve(video.target);
		});
	});

	const lazyVideos = document.querySelectorAll('.m-lazy-video');
	[...lazyVideos].forEach((video) => {
		videoObserver.observe(video);
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	mGlobal();
} else {
	document.addEventListener('DOMContentLoaded', mGlobal);
}
